.about-block {
    padding-top: 5%;
}

.about-title {
    font-size: 32px;
}

.about-me-text {
    font-size: 24px;
    padding-top: 6px;
}
