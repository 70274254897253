.intro-block-text {
    font-size: 25px;
    line-height: auto;
}

.name {
    font-size: 100px;
    font-weight: calc();
}

.desc {
    font-size: 32px;
    color: #444444;
}

.sunglasses-icon {
    margin-left: 10px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}